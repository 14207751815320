<template>
    <div class="why-register">
        <StackRouterHeaderBar :leftButtonHandler="leftButtonHandler" />
        <div class="content">
            <div class="title" v-html="`어떤 상대를 찾고 계신가요?`" />
            <div class="etc c-primary m-b-36 f-14" v-html="`* 복수 선택 가능`" />
            <div class="selections">
                <div
                    class="select m-b-12"
                    :class="{ selected: select.selected }"
                    v-for="select in selections"
                    v-html="select.name"
                    :key="select.id"
                    @click="onClick(select.id)"
                />
            </div>
        </div>
        <BottomButton @click="onClickButton" :disabled="!selectedArr.length" :label="$translate('START')" />
    </div>
</template>

<script>
import profileService from '@/services/profile'

export default {
    name: 'WhyRegisterPage',
    data: () => ({
        selectedArr: [],
        selections: [
            {
                id: 1,
                name: '연애 상대',
                selected: false,
            },
            {
                id: 2,
                name: '결혼 상대',
                selected: false,
            },
            {
                id: 3,
                name: '친구',
                selected: false,
            },
            {
                id: 4,
                name: '기타',
                selected: false,
            },
        ],
    }),
    methods: {
        leftButtonHandler() {
            const male = this.$store.getters.me.gender === 0
            if (male) {
                this.$toast.success(`가입이 완료되었어요 🙂 하트 5개를 드릴게요`, '', 3000)
            } else {
                this.$toast.success(`가입이 완료되었어요 🙂 하트 50개를 드릴게요`, '', 3000)
            }

            this.$router.push({ name: 'FeedPage', params: { isDirect: true } }).then(() => {
                this.$store.dispatch('loadBadges')
                this.$stackRouter.pop()
            })
        },
        rigButtonHandler() {
            this.leftButtonHandler()
        },
        onClick(id) {
            if (this.selectedArr.includes(id)) {
                this.selectedArr = this.selectedArr.filter(s => s !== id)
            } else {
                this.selectedArr.push(id)
            }

            this.selections = this.selections.map(s => ({
                ...s,
                selected: this.selectedArr.includes(s.id),
            }))
        },
        async onClickButton() {
            try {
                const profile = new FormData()
                profile.append('id', this.$store.getters.me.id)
                profile.append('why_start', this.selectedArr)
                const res = await profileService.update(profile)

                if (res) {
                    await this.$store.dispatch('loadFeeds')
                    const male = this.$store.getters.me.gender === 0
                    if (male) {
                        this.$toast.success(`가입이 완료되었어요 🙂 하트 5개를 드릴게요`, '', 3000)
                    } else {
                        this.$toast.success(`가입이 완료되었어요 🙂 하트 50개를 드릴게요`, '', 3000)
                    }

                    this.$router
                        .push({ name: 'FeedPage', params: { isDirect: true } })
                        .then(() => this.$stackRouter.pop())
                }
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.why-register {
    .content {
        padding: 0 16px;
    }
    .title {
        font-size: 24px;
        color: black;
        @include f-medium;
    }
    .selections {
        .select {
            &.selected {
                border: 1px solid $purple-primary;
                color: $purple-primary;
                @include f-medium;
            }
            border: 1px solid $grey-02;
            padding: 13px 0;
            border-radius: 8px;
            color: $grey-09;
            @include center;
        }
    }
}
</style>
